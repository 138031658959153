import React from 'react';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
//import WbSunnyTwoToneIcon from '@mui/icons-material/WbSunnyTwoTone';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { withRouter } from 'react-router';
import logo from "../../assets/logo.png";

class Bar extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { lng = 'lv', place = '', wtype },
      },
      location: { pathname },
      domain,
    } = props;

    if (domain === 'weather5days.com' && pathname === '/') {
      this.lng = 'en';
    } else {
        this.lng = lng;
    }

    if (place) {
      this.link = `${wtype}/${place}`;
    } else if (pathname.indexOf('/about') > 0) {
      this.link = 'about';
    } else if (pathname.indexOf('/all') > 0) {
      this.link = 'all';
    } else if (pathname.indexOf('/privacypolicy') > 0) {
      this.link = 'privacypolicy';
    } else {
      this.link = '';
    }

    this.links = {
      lv: {
        hrefLang: 'lv',
        title: 'Latviski',
        href: domain === 'weather5days.com' ? `/lv/${this.link}` : (this.link ? `/lv/${this.link}` : '/'),
      },
      ru: {
        hrefLang: 'ru',
        title: 'Русский',
        href: `/ru/${this.link}`,
      },
      en: {
        hrefLang: 'en',
        title: 'English',
        href: domain === 'weather5days.com' ? (this.link ? `/en/${this.link}` : '/') : `/en/${this.link}`,
      },
    };

    delete this.links[this.lng];
  }

  render() {
    const {
      domain,
    } = this.props;

    let logoLink = '/';
    if (domain === 'weather5days.com') {
      if (this.lng === 'ru' || this.lng === 'lv') {
        logoLink = `/${this.lng}/`;
      }
    } else {
      if (this.lng === 'ru' || this.lng === 'en') {
        logoLink = `/${this.lng}/`;

      }
    }

    return (
      <AppBar position="relative" style={{ backgroundColor: '#333' }}>
        <Toolbar>
          <Link className="logo-image-link" href={logoLink}>
            <img
                className="logo-image"
                src={logo}
                width="48"
                height="48"
                alt="Forecast.lv logo"
            />
          </Link>
          <Typography
            className={'logo-title'}
            variant="h6"
            color="inherit"
            noWrap
          >
            <Link color="inherit" className={'logo-title'} href={logoLink}>
              {domain === 'forecast.lv' ? 'Forecast' : 'Weather 5 days' }
            </Link>
          </Typography>
          <nav>
            {Object.keys(this.links).map((lng) => (
              <Box key={lng} px={'10px'}>
                <Link
                  href={this.links[lng].href}
                  rel="alternate"
                  hrefLang={this.links[lng].hrefLang}
                  variant="button"
                  color="inherit"
                >
                  {this.links[lng].title}
                </Link>
              </Box>
            ))}
          </nav>
        </Toolbar>
      </AppBar>
    );
  }
}
export default withRouter(Bar);
