import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';
import throttle from 'lodash.throttle';
import moment from 'moment';
import d2d from 'degrees-to-direction';
import i18n from './i18n';
import HomeC from  './containers/home';

import './Home.css';
import WeatherC1 from "./containers/weather";

//weather icons https://github.com/ClimaCell-API/weather-code-icons
//https://material-ui.com/components/autocomplete/#google-maps-place
//https://developer.climacell.co/v3/reference#get-hourly
//https://darksky.net/details/40.7127,-74.0059/2020-5-21/si12/en
//https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/combo-meteogram
class Home extends React.Component {
  constructor(props) {
    super(props);

  }

  static async getInitialProps({ req, res, match, history, domain, ...ctx }) {
    const {
      params: { lng = 'lv' },
      path
    } = match;

    const tempType = req?.session?.tempType === 'F' ? 'F' : 'C';

    if (domain === 'weather5days.com' && path === '/') {
      this.lng = 'en';
    } else {
      this.lng = lng;
    }

    if (req) {
      const data = await req.DBAPI.getLatest({
        lang: this.lng,
        cnt: 56,
        domain,
      });

      return {
        latest: data,
        domain,
        tempType
      };
    }

    return {};
  }

  render() {
    const { props } = this;
    const { latest, domain, tempType } = props;

    return (
      <div className="Home">
        <CssBaseline />
        <HomeC latest={latest} domain={domain} tempType={tempType} />
      </div>
    );
  }
}

export default Home;
