import React from 'react';
import Link from '@mui/material/Link';
import { withTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from 'axios';

const WeatherSelectTempType = ({ t, title, wtype, align = 'right' }) => {
  const onClick = async (e, type) => {
    e.preventDefault();
    await axios.get(`/api/temptype`, {
      params: { type },
    });
    global.location.reload();
  };

  return (
    <Box className={`text-${align}`}>
      <Typography variant="body2" component={'div'}>
        {t('typeOfTemperature')}:{' '}
        {wtype === 'C' ? (
          <Box className="font-size-14" display={'inline-block'}>
            °C
          </Box>
        ) : (
          <Link
            className="weather-link"
            color="secondary"
            title={`${t('titleWeather')} ${title} ${t('celsius')}`}
            onClick={(e) => onClick(e, 'C')}
          >
            °C
          </Link>
        )}{' '}
        {wtype === 'F' ? (
          <Box className="font-size-14" display={'inline-block'}>
            °F
          </Box>
        ) : (
          <Link
            className="weather-link"
            color="secondary"
            title={`${t('titleWeather')} ${title} ${t('fahrenheit')}`}
            onClick={(e) => onClick(e, 'F')}
          >
            °F
          </Link>
        )}
      </Typography>
    </Box>
  );
};

export default withTranslation('translations')(WeatherSelectTempType);
