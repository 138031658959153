import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
// import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import WeatherSelectTempType from "../WeatherSelectTempType";

const Latest = ({ data, t, domain, tempType, wtype, title }) => {
  let row1 = [];
  let row2 = [];
  let row3 = [];
  let row4 = [];

  const weatherCodeIcon = (icon, item) => {
    // sunrise: {value: "2020-08-05T02:31:48.370Z"}
    // sunset: {value: "2020-08-05T18:27:11.963Z"

    const codes = {
      1000: 113,
      1003: 116,
      1006: 119,
      1009: 122,
      1030: 143,
      1063: 176,
      1066: 179,
      1069: 182,
      1072: 185,
      1087: 200,
      1114: 227,
      1117: 230,
      1135: 248,
      1147: 260,
      1150: 263,
      1153: 266,
      1168: 281,
      1171: 284,
      1180: 293,
      1183: 296,
      1186: 299,
      1189: 302,
      1192: 305,
      1195: 308,
      1198: 311,
      1201: 314,
      1204: 317,
      1207: 320,
      1210: 323,
      1213: 326,
      1216: 329,
      1219: 332,
      1222: 335,
      1225: 338,
      1237: 350,
      1240: 353,
      1243: 356,
      1246: 359,
      1249: 362,
      1252: 365,
      1255: 368,
      1258: 371,
      1261: 374,
      1264: 377,
      1273: 386,
      1276: 389,
      1279: 392,
      1282: 395,
    };

    // item.startTime
    // if (
    //   codes[icon] === 'clear' ||
    //   codes[icon] === 'mostly_clear' ||
    //   codes[icon] === 'partly_cloudy'
    // ) {
    // if (
    //     moment.tz(item.startTime, 'Europe/Riga').hour() > 7 &&
    //     moment.tz(item.startTime, 'Europe/Riga').hour() < 20
    // ) {
/*    if (
        moment(item.time).hour() > 7 &&
        moment(item.time).hour() < 20
    ) {*/
    if (item.is_day) {
      return `day/${codes[icon]}`;
    } else {
      return `night/${codes[icon]}`;
    }
    // }

    // return codes[icon];
  }

  const getWeather = (elem) => {
    const intervals = elem.data?.forecastday;
    const timezone = elem?.timezone;
    let timeToSeach;
    if (timezone) {
      timeToSeach = moment.tz(moment(), timezone).format('YYYY-MM-DD HH:00');
    } else {
      timeToSeach = moment.tz(moment(),'UTC').format('YYYY-MM-DD HH:00');
    }
    if (!intervals) {
      return {};
    }

    let w = {};

    for (let i = 0; i < intervals.length; i++) {
      const row = intervals[i].hour.find(element => element.time === timeToSeach);

      if (row) {
        w = row;
        break;
      }
    }

    return w;
  }

  if (data) {
    data.forEach((elem, i) => {
      if (i < 14) {
        row1.push({...elem, w: getWeather(elem)});
      } else if (i < 28) {
        row2.push({...elem, w: getWeather(elem)});
      } else if (i < 42) {
        row3.push({...elem, w: getWeather(elem)});
      } else {
        row4.push({...elem, w: getWeather(elem)});
      }
    });
  }

  return (
    <div className={'text-center'}>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={12} sm={12} md={8} align="center">
          <p>{domain === 'forecast.lv' ? t('popularWeather') : t('popularWeather_global')}</p>
        </Grid>
      </Grid>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={6} sm={3} md={3}>
          {row1.map((item, i) => (
            <Box key={'a' + (i + 1)} align={'left'} mb={'3px'}>
{/*              <Badge
                color="secondary"
                badgeContent={i + 1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >*/}
                <Link
                  color="secondary"
                  className={'latest-link'}
                  href={`/${item.lang}/w/${item.url}`}
                  title={`${t('titleWeather')} ${item.title}`}
                >
                  {item.title}
                </Link>
                {item.w.condition?.code &&
                    <span className="latest-icon" style={{backgroundImage: `url("/w/icon2/${weatherCodeIcon(item.w.condition.code, item.w)}.png")`}}></span>}

              {tempType === 'C' && item.w.temp_c && <span className="latest-temp">{item.w.temp_c} °</span>}
              {tempType === 'F' && item.w.temp_f && <span className="latest-temp">{item.w.temp_f} °</span>}
{/*              </Badge>*/}
            </Box>
          ))}
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          {row2.map((item, i) => (
            <Box key={'b' + (i + 1)} align={'left'} mb={'3px'}>
{/*              <Badge
                color="secondary"
                badgeContent={i + 11}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >*/}
                <Link
                  color="secondary"
                  className={'latest-link'}
                  href={`/${item.lang}/w/${item.url}`}
                  title={`${t('titleWeather')} ${item.title}`}
                >
                  {item.title}
                </Link>
                {item.w.condition?.code &&
                    <span className="latest-icon" style={{backgroundImage: `url("/w/icon2/${weatherCodeIcon(item.w.condition.code, item.w)}.png")`}}></span>}
              {tempType === 'C' && item.w.temp_c && <span className="latest-temp">{item.w.temp_c} °</span>}
              {tempType === 'F' && item.w.temp_f && <span className="latest-temp">{item.w.temp_f} °</span>}
{/*              </Badge>*/}
            </Box>
          ))}
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          {row3.map((item, i) => (
              <Box key={'c' + (i + 1)} align={'left'} mb={'3px'}>
{/*                <Badge
                    color="secondary"
                    badgeContent={i + 21}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                >*/}
                  <Link
                      color="secondary"
                      className={'latest-link'}
                      href={`/${item.lang}/w/${item.url}`}
                      title={`${t('titleWeather')} ${item.title}`}
                  >
                    {item.title}
                  </Link>
                  {item.w.condition?.code &&
                      <span className="latest-icon" style={{backgroundImage: `url("/w/icon2/${weatherCodeIcon(item.w.condition.code, item.w)}.png")`}}></span>}
                {tempType === 'C' && item.w.temp_c && <span className="latest-temp">{item.w.temp_c} °</span>}
                {tempType === 'F' && item.w.temp_f && <span className="latest-temp">{item.w.temp_f} °</span>}
{/*                </Badge>*/}
              </Box>
          ))}
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          {row4.map((item, i) => (
              <Box key={'d' + (i + 1)} align={'left'} mb={'3px'}>
{/*                <Badge
                    color="secondary"
                    badgeContent={i + 31}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                >*/}
                  <Link
                      color="secondary"
                      className={'latest-link'}
                      href={`/${item.lang}/w/${item.url}`}
                      title={`${t('titleWeather')} ${item.title}`}
                  >
                    {item.title}
                  </Link>
                  {item.w.condition?.code &&
                      <span className="latest-icon" style={{backgroundImage: `url("/w/icon2/${weatherCodeIcon(item.w.condition.code, item.w)}.png")`}}></span>}
                {tempType === 'C' && item.w.temp_c && <span className="latest-temp">{item.w.temp_c} °</span>}
                {tempType === 'F' && item.w.temp_f && <span className="latest-temp">{item.w.temp_f} °</span>}
{/*                </Badge>*/}
              </Box>
          ))}
        </Grid>
      </Grid>
      <Grid container pt={'12px'} spacing={0} direction="row" justifyContent="left">
        <WeatherSelectTempType
            title={title}
            wtype={wtype}
        />
      </Grid>
    </div>
  );
};

Error.propTypes = {
  data: PropTypes.array,
};

Error.defaultProps = {
  data: [],
};

export default withTranslation('translations')(Latest);
