import React from 'react';
// import logo from './react.svg';
import { Helmet } from 'react-helmet';
// import CookieConsent from "react-cookie-consent";
// import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import axios from 'axios';
import throttle from 'lodash.throttle';
// import moment from 'moment';
// import d2d from 'degrees-to-direction';
import Bar from '../../components/Bar';
import Latest from '../../components/Latest';
import Box from '@mui/material/Box';
import { Trans, withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Link from '@mui/material/Link';
import nexoImg from '../../assets/nexo-a.png';

//weather icons https://github.com/ClimaCell-API/weather-code-icons
//https://material-ui.com/components/autocomplete/#google-maps-place
//https://developer.climacell.co/v3/reference#get-hourly
//https://darksky.net/details/40.7127,-74.0059/2020-5-21/si12/en
//https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/combo-meteogram
class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      title: '',
      dataHourly: [],
      result: [],
      entered: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.click = this.click.bind(this);
    this.search = this.search.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.setPage = this.setPage.bind(this);
    // this.getCurrentTime = this.getCurrentTime.bind(this);
    this.throttleSearch = throttle((search) => this.search(search), 500);
  }

  lng() {
    const {
      i18n: { language = i18n.language },
    } = this.props;

    // const {
    //     match: {
    //         params: { lng = i18n.language },
    //     },
    // } = this.props;

    return language;
  }
  async search(s) {
    const res1 = await axios.get(`/api/autocomplete`, {
      params: { place: s, ln: this.lng() },
    });
    this.setState({
      result: res1.data || []
    });
    return res1.data || [];
  }

  async handleChange(e) {
    const search = e.target.value;
    if (search) {
      const res = await this.throttleSearch(search);

      // this.setState({
      //   result: res,
      // });
    }
  }

  foundData(result, value) {
    return result.filter((item) => item.description === value);
  }

  async onInputChange(e, value) {
    const data = this.foundData(this.state.result, value)[0] || {};
    if (data.structured_formatting && data.structured_formatting.main_text) {
      // const country1 = value.substring(value.lastIndexOf(',') + 1).trim().toLowerCase();
      // let city1 =  value.substring(value.indexOf(','), -1).trim().toLowerCase();

      const res1 = await axios.get(`/api/place`, {
        params: { place: value },
        //params: { place: city1 + ',' + country1, ln: 'lv' },
      });

      const { results = [] } = res1.data;
      if (results[0]?.formatted_address) {
        // console.log(value, JSON.stringify(data), JSON.stringify(results[0]));
        // if (data.structured_formatting.main_text) {
        let city;
        if (results[0].formatted_address.indexOf('pilsēta') > 0) {
          // last
          const country = results[0].formatted_address
            .substring(results[0].formatted_address.lastIndexOf(',') + 1)
            .trim().replace(/\//g, ' ');
          // first
          city =
            results[0].formatted_address
              .substring(results[0].formatted_address.indexOf(','), -1)
              .trim().replace(/\//g, ' ') +
            ',' +
            country;
        } else {
          city = results[0].formatted_address.replace(/\//g, ' ');
        }
        city = city.replace(/, LV-\d+/, '');
        city = city.replace(/, /g, ',').toLowerCase();
        // console.log(city);
        global.location = `/${this.lng()}/w/${city}`;
      } else {
        value = value.replace(/, /g, ',').replace(/\//g, ' ').toLowerCase();
        global.location = `/${this.lng()}/w/${value}`;
      }
    }
  }

  async click() {
    // todo in local API
    if (this.state.entered) {
      //console.log(this.state.entered);
      // Search weather
      // const res1 = await axios.get(`/api/autocomplete`, {
      //   params: { place: this.state.entered },
      // });
      // console.log(this.state.entered);
      // global.location = `/${this.lng()}/w/${this.state.entered}`;
      // const res2 = await axios.get(`/api/place`, {
      //   params: { place: this.state.entered, ln: this.lng() },
      // });
      //
      // const { results = [] } = res2.data;
      //
      // if (results[0]) {
      //   const weather = await axios.get(`/api/weather/hourly`, {
      //     params: {
      //       lat: results[0].geometry.location.lat,
      //       lon: results[0].geometry.location.lng,
      //     },
      //   });
      //
      //   this.setState({
      //     title: results[0].name,
      //     dataHourly: weather.data,
      //   });
      //
      //   console.log(weather.data);
      // }
      // try {
      //   const response = await Places.search({
      //     input: this.state.place,
      //     components: 'country:lv',
      //     language: 'lv',
      //   });
      //   console.log(response.data);
      //   // return { places: response.data };
      // } catch (error) {
      //   console.log(error.message);
      //   // return {
      //   //   places: {
      //   //     error: error.message,
      //   //   },
      //   // };
      // }
    }
  }
  // weatherCodeIcon(icon, item) {
  //   // sunrise: {value: "2020-08-05T02:31:48.370Z"}
  //   // sunset: {value: "2020-08-05T18:27:11.963Z"
  //
  //   const codes = {
  //     4201: 'rain_heavy',
  //     4001: 'rain',
  //     4200: 'rain_light',
  //     6201: 'freezing_rain_heavy',
  //     6001: 'freezing_rain',
  //     6200: 'freezing_rain_light',
  //     6000: 'freezing_drizzle',
  //     4000: 'drizzle',
  //     7101: 'ice_pellets_heavy',
  //     7000: 'ice_pellets',
  //     7102: 'ice_pellets_light',
  //     5101: 'snow_heavy',
  //     5000: 'snow',
  //     5100: 'snow_light',
  //     5001: 'flurries',
  //     8000: 'tstorm',
  //     2100: 'fog_light',
  //     2000: 'fog',
  //     1001: 'cloudy',
  //     1102: 'mostly_cloudy',
  //     1101: 'partly_cloudy',
  //     1100: 'mostly_clear',
  //     1000: 'clear',
  //   };
  //
  //   // item.startTime
  //   if (
  //     codes[icon] === 'clear' ||
  //     codes[icon] === 'mostly_clear' ||
  //     codes[icon] === 'partly_cloudy'
  //   ) {
  //     if (
  //       moment.tz(item.startTime, 'Europe/Riga').diff(7) > 0 &&
  //       moment.tz(item.startTime, 'Europe/Riga').diff(20) < 0
  //     ) {
  //       return `${codes[icon]}_day`;
  //     } else {
  //       return `${codes[icon]}_night`;
  //     }
  //   }
  //
  //   return codes[icon];
  // }

  // prepareData(data = []) {
  //   const obj = {};
  //
  //   data.map((item) => {
  //     const group_date = moment
  //       .tz(item.startTime, 'Europe/Riga')
  //       .format('YYYY-MM-DD');
  //     if (!(group_date in obj)) {
  //       obj[group_date] = {
  //         seriesTemperature: [],
  //         time: [],
  //         precipitationIntensity: [],
  //         weatherCode: [],
  //         windSpeed: [],
  //         windDirection: [],
  //         humidity: [],
  //         pressureSeaLevel: [],
  //         cloudCover: [],
  //       };
  //     }
  //     obj[group_date].seriesTemperature.push({
  //       y: item.values.temperature,
  //       marker: {
  //         symbol: `url(/w/icon/${this.weatherCodeIcon(
  //           item.weatherCode.value,
  //           item
  //         )}.svg)`,
  //         width: 40,
  //         height: 40,
  //       },
  //     });
  //     obj[group_date].precipitationIntensity.push(
  //       item.precipitationIntensity.value
  //     );
  //     obj[group_date].weatherCode.push(item.weatherCode.value);
  //     obj[group_date].windSpeed.push(item.windSpeed.value);
  //     obj[group_date].windDirection.push(item.windDirection.value);
  //     obj[group_date].humidity.push(item.humidity.value);
  //     obj[group_date].pressureSeaLevel.push(item.pressureSeaLevel.value);
  //     obj[group_date].cloudCover.push(item.cloudCover.value);
  //     obj[group_date].time.push(
  //       moment.tz(item.startTime, 'Europe/Riga').format()
  //     );
  //   });
  //   return obj;
  // }
  setPage(i) {
    this.setState({
      current: i,
    });
  }
  // getCurrentTime() {
  //   const data = this.prepareData(this.state.dataHourly);
  //   const today = data[Object.keys(data)[this.state.current]];
  //   const { time } = today || {};
  //
  //   return (time && time[0]) || {};
  // }
  render() {
    const { tempType } = this.props;
    return (
      <div>
        <Helmet>
          <title>
            {this.props.domain === 'forecast.lv'
              ? this.props.t('home.metatitle')
              : this.props.t('home.metatitle_global')}
          </title>
          <meta
            name="description"
            content={
              this.props.domain === 'forecast.lv'
                ? this.props.t('home.metadescription')
                : this.props.t('home.metadescription_global')
            }
          />
          <meta
            name="keywords"
            content={
              this.props.domain === 'forecast.lv'
                ? this.props.t('home.metakeywords', {
                    domain: this.props.domain,
                  })
                : this.props.t('home.metakeywords_global', {
                    domain: this.props.domain,
                  })
            }
          />
        </Helmet>
        <Bar domain={this.props.domain} />
        {/*<Box
          pt={'12px'}
          pb={'12px'}
          textAlign={'center'}
          style={{ backgroundColor: '#eee' }}
        >
          <Box
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12}>

                            <Button variant="contained" color="primary" onClick={this.click}>
                Go
              </Button>
            </Grid>
          </Box>
        </Box>*/}
        <Container component="main">
          <Box mb={'16px'}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12}>
                <Box pt={'24px'}>
                  <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                  >
                    {this.props.domain === 'forecast.lv'
                      ? this.props.t('home.title')
                      : this.props.t('home.title_global')}
                  </Typography>
                  <Typography
                    variant="h5"
                    align="center"
                    color="textSecondary"
                    paragraph
                  >
                    {this.props.domain === 'forecast.lv'
                      ? this.props.t('home.description')
                      : this.props.t('home.description_global')}
                  </Typography>
                </Box>
              </Grid>
              <Grid container spacing={0} justifyContent="center">
                <Grid item xs={8} sm={6} md={4}>
                  <Autocomplete
                    className="place-search"
                    size="small"
                    id="place"
                    noOptionsText={this.props.t('home.entercitysearch')}
                    style={{ width: '100%' }}
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.description
                    }
                    filterOptions={(x) => x}
                    options={this.state?.result}
                    autoComplete
                    includeInputInList
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={this.props.t('home.citysearch')}
                        variant="outlined"
                        fullWidth
                        onChange={this.handleChange}
                      />
                    )}
                    onInputChange={this.onInputChange}
                    renderOption={(props, option) => {
                      const matches =
                        option.structured_formatting
                          .main_text_matched_substrings;
                      const parts = parse(
                        option.structured_formatting.main_text,
                        (matches &&
                          matches.map((match) => [
                            match.offset,
                            match.offset + match.length,
                          ])) ||
                          []
                      );

                      return (
                        <Grid container alignItems="center" {...props}>
                          <Grid item>
                            <LocationOnIcon />
                          </Grid>
                          <Grid item xs>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Latest
            data={this.props.latest}
            domain={this.props.domain}
            tempType={tempType}
            wtype={this.props.tempType}
            title={
              this.props.domain === 'forecast.lv'
                ? this.props.t('home.metatitle')
                : this.props.t('home.metatitle_global')
            }
          />
          {this.lng() === 'en' && (
            <Box mt={'20px'} className={'text-center'}>
              <a
                href="https://nexo.com/ref/76zq50vfyz?src=web-link"
                target="_blank"
                id="1540626"
              >
                <img className="banner" src={nexoImg} border="0" alt="" />
              </a>
            </Box>
          )}
          {/*          {this.lng() === 'en' && (
            <Box mt={'40px'} className={'text-center'}>
              <a
                href="https://nexo.io/ref/76zq50vfyz?src=web-link"
                title="Nexo earn $25 in BTC"
                target="_blank"
              >
                <img
                  className="banner"
                  src={nexo}
                  alt="Nexo earn $25 in BTC referal bonus image"
                />
              </a>
            </Box>
          )}
          {this.lng() === 'lv' && (
              <>
                <Box mt={'30px'} className={'text-center banner-768'}>
                  <ins className="bookingaff" data-aid="2262590" data-target_aid="2262590" data-prod="banner"
                       data-width="728" data-height="90" data-lang="lv">
                    <a href="//www.booking.com?aid=2262590" target="_blank">Booking.com</a>
                  </ins>
                </Box>
                <Box mt={'30px'} className={'text-center banner-300'}>
                  <ins className="bookingaff" data-aid="2262592" data-target_aid="2262592" data-prod="banner"
                  data-width="300" data-height="250" data-lang="lv">
                  <a href="//www.booking.com?aid=2262592" target="_blank">Booking.com</a>
                  </ins>
                </Box>
              </>
          )}
          {this.lng() === 'ru' && (
              <>
                <Box mt={'30px'} className={'text-center banner-768'}>
                  <ins className="bookingaff" data-aid="2262597" data-target_aid="2262597" data-prod="banner" data-width="728"
                       data-height="90" data-lang="ru">
                    <a href="//www.booking.com?aid=2262597" target="_blank">Booking.com</a>
                  </ins>
                </Box>
                <Box mt={'30px'} className={'text-center banner-300'}>
                  <ins className="bookingaff" data-aid="2262599" data-target_aid="2262599" data-prod="banner"
                       data-width="300" data-height="250" data-lang="ru">
                    <a href="//www.booking.com?aid=2262599" target="_blank">Booking.com</a>
                  </ins>
                </Box>
              </>
          )}*/}
          <Box pt={'20px'} pl={'2px'}>
            <Typography variant="body2" component={'div'}>
              <Trans i18nKey="home.descr1">
                Weather forecasts, real weather observations, historical news
                data.
              </Trans>
              <p>
                {this.props.domain === 'forecast.lv' ? (
                  <Trans i18nKey="home.descr2">
                    Our weather news system compiles and provides a detailed
                    meteorological and hydrological forecast for all regions of
                    Latvia, adapted to the individual needs of people who want
                    to receive the latest weather news. A detailed weather
                    forecast is compiled, which the system updates three times a
                    day. Weather reports from certified online stations are
                    displayed in real time, providing accurate local weather
                    information.
                  </Trans>
                ) : (
                  <Trans i18nKey="home.descr2_global">
                    Our weather news system compiles and provides a detailed
                    meteorological and hydrological forecast for all regions of
                    all cities, adapted to the individual needs of people who
                    want to receive the latest weather news. A detailed weather
                    forecast is compiled, which the system updates three times a
                    day. Weather reports from certified online stations are
                    displayed in real time, providing accurate local weather
                    information.
                  </Trans>
                )}
              </p>
              <p>
                <Trans i18nKey="home.descr3">
                  Our goals are to achieve and offer impeccable service quality
                  in all settlements and to every person on a global scale. Our
                  profile is formed by our broad vision and objectivity
                  regarding all inhabited and uninhabited places, as well as
                  densely populated and sparsely populated regions on this
                  planet.
                </Trans>
              </p>
            </Typography>
          </Box>
          <footer className="footer">
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} className={'text-center'}>
                <Link
                  color="inherit"
                  className={'footer-link'}
                  href={`/${this.props.i18n.language}/about`}
                  title={this.props.t('aboutus', { domain: this.props.domain })}
                >
                  {this.props.t('aboutus', { domain: this.props.domain })}
                </Link>{' '}
                |{' '}
                <Link
                  color="inherit"
                  className={'footer-link'}
                  href={`/${this.props.i18n.language}/privacypolicy`}
                  title={this.props.t('privacyPolicyLink')}
                >
                  {this.props.t('privacyPolicyLink')}
                </Link>
                {this.props.domain === 'forecast.lv' && (
                  <>
                    {' '}
                    |{' '}
                    <Link
                      color="inherit"
                      className={'footer-link'}
                      href={`https://weather5days.com`}
                      title={'weather5days.com'}
                      target={'_blank'}
                    >
                      weather5days.com
                    </Link>
                  </>
                )}
              </Grid>
            </Grid>
          </footer>
        </Container>
        {/*        <CookieConsent
            debug={false}
            enableDeclineButton={false}
            buttonText={this.props.t('cookieAccept')}
            buttonClasses={'btn'}
            disableButtonStyles={true}
        >
          {this.props.t('cookieText', { domain: this.props.domain })}
        </CookieConsent>*/}
      </div>
    );
  }
}

export default withTranslation('translations')(Home);
